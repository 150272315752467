<template>
<div>
  <img :src="require('../assets/qrcode.jpg')" alt="">
</div>
</template>

<script>
export default {
  name: "payment"
}
</script>

<style scoped>

</style>